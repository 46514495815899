import Check from '../../assets/icons/GreenCheck.svg';

const Included = () => {
    return (
        <>
            <img src={Check} width={18} alt='included' />
        </>
    );
}

export { Included }