import styled from "styled-components"
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { PWRTextLink } from "../styled/Buttons";
import { FeaturesTable } from "./FeaturesTable";

const PWBModalStyled = styled.div`

    .details-modal {
        font-weight: bold;
        cursor: pointer;
    }
`



const FeaturesModal = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <>

            <PWBModalStyled>
                <PWRTextLink onClick={handleShow} className="details-modal">
                    See all features
                </PWRTextLink>

                <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <FeaturesTable />
                    </Modal.Body>
                </Modal>
            </PWBModalStyled>
        </>
    );
}


export { FeaturesModal }
