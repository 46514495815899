import { gql } from "@apollo/client";

const CONTACT_US = gql`
  mutation ContactUs($input: ContactUsInput) {
    contactUs(input: $input)
  }
`;

const STAY_UPDATED = gql`
  mutation AddNewsletterUser($email: String!) {
    addNewsletterUser(email: $email)
  }
`;

export { CONTACT_US, STAY_UPDATED };
