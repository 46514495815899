import { NavLink } from 'react-router-dom'
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import '../GlobalStyle.css';
import styled from 'styled-components';
import { PWRPrimaryButton } from '../styled/Buttons';

const MobileCTA = styled.div`
    border-top: 1px solid #ccc;
    width: 100%;
    margin: auto;
    text-align: center;
    padding-top: 1rem;
`

const ServiceBookmarks = styled.div`
    position: fixed;
    width: 100%;
    text-align: center;
    margin: auto;
    z-index: 1;

.service-bookmark-links {
    scroll-padding-top: 20rem;
}

    .nav-item:hover, .nav-item:active, .navbar-nav .active {
        background-color: #f5f5f5;
        border-radius: .25rem;
        border-bottom: none;
    }
`

const Navigation = () => {
    return (
        <>
            <Navbar collapseOnSelect expand="md" className='shadow-sm bg-white' sticky='top'>
                <Container fluid>
                    <Navbar.Brand href="/"><img src="images\Power Boom - Horizontal Color.png" alt="Power Boom Logo" width='200px' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <NavLink className='nav-item' to='/'>Home</NavLink>
                            <NavLink className='nav-item' to="/services">Services</NavLink>
                            <NavLink className='nav-item' to="/service-packages">Service Packages</NavLink>
                            <NavLink className='nav-item' to="/about">About</NavLink>
                            <PWRPrimaryButton href='/service-packages' className='d-none d-lg-inline'>Get Started</PWRPrimaryButton>
                        </Nav>
                    </Navbar.Collapse>
                    <MobileCTA className='my-2 d-lg-none'>
                        <PWRPrimaryButton className='fs-18' href='/service-packages'>Get Started</PWRPrimaryButton>
                    </MobileCTA>
                </Container>
            </Navbar>
        </>
    );
}


const ServicesNavigation = () => {
    return (
        <>
            <ServiceBookmarks className='d-none d-md-block'>
                <Navbar className='bg-white border-bottom'>
                    <Container fluid>
                        <Nav className='mx-auto'>
                            <Nav.Link href='#chargingdemand' className='service-bookmark-links'>Charging demand assessment</Nav.Link>
                            <Nav.Link href='#competitive'>Charging competitive analysis</Nav.Link>
                            <Nav.Link href='#sitehostneeds'>Site host needs deep dive</Nav.Link>
                            <Nav.Link href='#sitehostassessment'>Site assessment</Nav.Link>
                            <Nav.Link href='#solutions'>Solutions review</Nav.Link>
                            <Nav.Link href='#incentives'>Incentive identification</Nav.Link>
                            <Nav.Link href='#procurement'>Procurement guidance</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </ServiceBookmarks>
        </>
    );
}

export { Navigation, ServicesNavigation }