import { Container, Row, Col } from 'react-bootstrap';
import { Navigation, ServicesNavigation } from '../components/Layout/Navigation';
import CTA from '../components/Layout/CTA';
import Footer from '../components/Layout/Footer';
import { ProjectPlannerCartCard } from '../components/PaymentCart/ServicePackageCartCards';
import { Helmet } from 'react-helmet';
import '../components/GlobalStyle.css';
import { CartNavigation } from '../components/Layout/CartNavigation';

const CartPlanner = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Project Planner | Service Packages</title>
                <link rel="canonical" href="https://pwrboom.com/cart-project-planner" />
            </Helmet>
            <CartNavigation />
            <Container>
                <Row className='py-5 justify-content-center text-center'>
                    <Col lg={7}>
                        <h1 className='fs-36'>You're taking the first step towards <strong>providing EV charging at your facility</strong>
                        </h1>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col lg={5} className='my-2'>
                        <ProjectPlannerCartCard />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}

export default CartPlanner;