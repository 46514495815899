import { Navigation } from '../components/Layout/Navigation';
import Footer from '../components/Layout/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import Stepper from '../components/Contact/Stepper'
import '../components/GlobalStyle.css';
import { PWRTextLink } from '../components/styled/Buttons';
import { Helmet } from 'react-helmet';
import phone from '../assets/icons/call.png';
import mail from '../assets/icons/mail.png';
import { useMediaQuery } from 'react-responsive';

const ContactUs = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 }); 
    return (
        <>
            { isMobile ? <MobileContactUs/> : <DesktopContactUs/>}
        </>
    );
}

const DesktopContactUs = () => {
    return (
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Power Boom | Contact Us</title>
                <link rel="canonical" href="https://pwrboom.com/contact-us" />
            </Helmet>
            <Navigation />
            <Container fluid className=''>
                <Row className='justify-content-between align-items-center'>
                    <Col lg={{ span: 4, offset: 1 }} className='p-5 p-lg-0'>
                        <h1 className='fs-60'>
                            Get in touch
                        </h1>
                        <p>
                            Have a question about our service packages? Need help choosing the right package for your facility? Contact us today.
                        </p>
                        <hr />
                        <PWRTextLink className='fw-bold text-decoration-none'><img src={phone} width={18} className='me-2' />1-858-799-4583</PWRTextLink>
                        <p>
                            We're available 7 am - 7pm PST for more information
                        </p>

                        <PWRTextLink className='fw-bold' href='mailto:info@pwrboom.com'><img src={mail} width={18} className='me-2' />info@PWRBoom.com</PWRTextLink>
                    </Col>
                    <Col lg={6}>
                        <Stepper />
                    </Col>
                </Row>
            </Container>
            <br/>
            <br/>
            <br/>
            <Footer />
        </>
    )
}

const MobileContactUs = () => {
    return (
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Power Boom | Contact Us</title>
                <link rel="canonical" href="https://pwrboom.com/contact-us" />
            </Helmet>
            <Navigation />
            <Container fluid className=''>
                <Row className='justify-content-between align-items-center'>
                    <Col lg={{ span: 4, offset: 1 }} className='pt-0 p-lg-0'>
                        <h1 className='fs-60'>
                            Get in touch
                        </h1>
                        <p>
                            Have a question about our service packages? Need help choosing the right package for your facility? Contact us today.
                        </p>
                        <hr />
                        <PWRTextLink className='fw-bold text-decoration-none'><img src={phone} width={18} className='me-2' />1-858-799-4583</PWRTextLink>
                        <p>
                            We're available 7 am - 7pm PST for more information
                        </p>

                        <PWRTextLink className='fw-bold' href='mailto:info@pwrboom.com'><img src={mail} width={18} className='me-2' />info@PWRBoom.com</PWRTextLink>
                        <br/>
                        <br/>
                    </Col>
                    <Col lg={6}>
                        <Stepper />
                    </Col>
                </Row>
            </Container>
            <br/>
            <br/>
            <br/>
            <Footer />
        </>
    )
}

export default ContactUs;