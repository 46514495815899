import styled from "styled-components"

const PWRPrimaryButton = styled.a`
    color: #fff;
    background-color: #009247;
    border: 1px solid #009247;
    border-radius: .4rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.1rem;
    vertical-align: middle;
    display: inline;
    padding: .5rem 1rem;
    text-align: center;
    transition: .25s ease;

    &:hover {
        color: #ffffff !important;
        background-color: #005B23 !important;
        border: 1px solid #005B23 !important;
    }
    @media (max-width: 767px) {
        /* Media query for screens smaller than 768px (typical mobile screens) */
        text-align: center;  /* Center the text */
        white-space: nowrap;
    }
`

const PWRPrimaryButtonDark = styled.a`
    color: #fff;
    background-color: #005B23;
    border: 1px solid #005B23;
    border-radius: .4rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.1rem;
    vertical-align: middle;
    display: inline;
    padding: .5rem 1rem;
    text-align: center;
    transition: .25s ease;

    &:hover {
        color: #005B23 !important;
        background-color: transparent !important;
        border: 1px solid #005B23 !important;
    }
`

const PWRPrimaryBtn = styled.button`
    color: #fff;
    background-color: #009247;
    border: 1px solid #009247;
    border-radius: .4rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1.1rem;
    vertical-align: middle;
    display: inline;
    padding: .5rem 1rem;
    text-align: center;
    transition: .25s ease;

    &:hover {
        color: #ffffff !important;
        background-color: #005B23 !important;
        border: 1px solid #005B23 !important;
    }
`

const PWRSecondaryButton = styled.a`
    color: #009247;
    border: 1px solid #009247;
    border-radius: .4rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.15rem;
    line-height: 1.25rem;
    vertical-align: middle;
    display: inline;
    padding: .5rem 1rem;
    text-align: center;
    transition: .25s ease;

    &:hover {
        border: 1px solid #009247 !important;
        color: #fff;
        background-color: #009247;
    }
    @media (max-width: 767px) {
        /* Media query for screens smaller than 768px (typical mobile screens) */
        text-align: center;  /* Center the text */
        font-size: 1rem;
        white-space: nowrap;
    }
`

const PWRTextLink = styled.a`
    color: #009247;

    &:hover {
        color: #009247;
    }
`

const PWRArrowLink = styled.a`
    color: #0478c2;
    text-decoration: none;
    font-weight: bold;
    transition: .125s ease;
    margin-left: 0rem;

    svg {
        fill: #0478c2;
        transition: .125s ease;
    }

    &:hover {
        color: #1c304c;

        svg {
            margin-left: .5rem;
            fill: #1c304c;
            transition: .125s ease;
        }
    }
`

const BackButton = styled.a`
    color: #707070;
    text-decoration: none;
    transition: .125s ease;
    margin-left: 0rem;

    svg {
        fill: #707070;
        transition: .125s ease;
        vertical-align: text-top;
        margin-top: .115rem;
    }

    &:hover {
        color: #333333;

        svg {
            fill: #333333;
            transition: .125s ease;
        }
    }
`


const BackArrow = () => {
    return (
        <>

            <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 -960 960 960" width="24"><path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z" /></svg>

        </>
    );
}


export { PWRPrimaryButton, PWRPrimaryButtonDark, PWRPrimaryBtn, PWRSecondaryButton, PWRTextLink, PWRArrowLink, BackButton, BackArrow }
