import styled from "styled-components"

const PWBAccordionStyled = styled.div`

    .accordion-item {
        border: none;
        color: #333333;
        background: transparent;

    }

    .accordion-body {
        img {
            margin: auto;
        }
    }

    .accordion-header {
        background-color: transparent;
        font-weight: bold !important;
        border: none !important;
    }
    

    .accordion-button:not(.collapsed) {
        background-color: transparent;
        color: #333333;
        box-shadow: none;
    }

    .accordion-button { 
        background-color: transparent;
        font-weight: bold;
        font-size: 1.25rem;
        color: #333333;
        border-top: 1px solid #333333;
        border-radius: 0px;

        &:focus {
            box-shadow: none;
        }
    }

`

export { PWBAccordionStyled }
