import styled from "styled-components"

const FadeScroll = styled.div`
    animation: animate-in linear both;
    animation-timeline: view();
    animation-range: entry-crossing 10% contain 10%;

@keyframes animate-in {
    0% { 
        opacity: 0; 
        scale: .9;
    }
    100% { 
        opacity: 1;
        scale: 1;
    }
  }

`

const FadeLoad = styled.div`
animation: fade-in 1s ease-in-out both;

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(1rem);
    }
    to {
        opacity: 1;
        transform: translateY(0rem);
    }
}
`

const FadeLoadDelay = styled.div`
animation: fade-in 1s ease-in-out .5s both;

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(1rem);
    }
    to {
        opacity: 1;
        transform: translateY(0rem);
    }
}
`

const Floating = styled.div`
animation: float 2s linear infinite alternate;

@keyframes float {
    from {
        transform: translateY(1rem);
    }
    to {
        transform: translateY(0rem);
    }
}
`


export { FadeScroll, FadeLoad, FadeLoadDelay, Floating }
