import { Navigation } from '../components/Layout/Navigation';
import Footer from '../components/Layout/Footer';
import { Container, Row, Col, Button } from 'react-bootstrap'
import styled from 'styled-components'
import '../components/GlobalStyle.css';
import { Helmet } from 'react-helmet';

import AboutImg from '../assets/images/About.png';
import AboutImg2 from '../assets/images/AboutImg2.png';
import CTA from '../components/Layout/CTA';

import Sushant from '../assets/staff/SushantV.png';
import Gina from '../assets/staff/GinaG.png';
import Makena from '../assets/staff/MakenaT.png';
import Dawn from '../assets/staff/DawnC.png';
import Bridget from '../assets/staff/BridgetE.png';
import { FadeLoad } from '../components/styled/Motion.styled';


const About = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us | Power Boom</title>
                <link rel="canonical" href="https://pwrboom.com/about" />
                <meta name='description' content='Customized EV charging for your facility' />
            </Helmet>
            <Navigation />
            <Container fluid>
                <Row className='justify-content-between align-items-center pt-5 pt-md-0'>
                    <Col md={{ span: 5, offset: 1 }}>
                        <FadeLoad>
                            <h4 className='text-uppercase'>About Power Boom</h4>
                            <h1 className='fw-normal fs-60'>Our expert team makes it simple to set up electric vehicle charging.</h1>
                            <p className='fs-5'>
                                Whether you manage multi-family unit housing, oversee hotels, operate shopping centers, host events, own wineries, run auto-repair shops, or coordinate employee transportation, Power Boom has you covered.
                            </p>
                            <p>
                                We're your shortcut to finding the right charging solution for your site —enabling you to quickly maximize the value of offering on-site EV charging.
                            </p>
                        </FadeLoad>
                    </Col>
                    <Col md={5}>
                        <img src={AboutImg} width='100%' alt='about us' />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='vh-75'>
                <Row className='align-items-center'>
                    <Col md={4} className='order-2 order-md-1'>
                        <img src={AboutImg2} width='100%;' />
                    </Col>
                    <Col md={{ span: 6, offset: 1 }} className='order-1 order-md-2 py-5 py-md-0'>
                        <h2>
                            Electric vehicle driving is growing exponentially. Access to electric vehicle charging needs to catch up.
                        </h2>
                        <h2 className='fw-bold'>
                            That's why we founded Power Boom.
                        </h2>
                        <p>
                            Where there are electric vehicles, there's the demand to charge them while drivers work, sleep, shop, and play.
                        </p>
                        <p>
                            Across the country, roads are filling up with more and more EVs. Many facility operators recognize that providing on-site electric vehicle charging will generate massive value, but are unsure how to best set up charging stations and networks.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='bg-sun text-center py-5'>
                <Row className='justify-content-center py-5'>
                    <Col md={8}>
                        <h2>
                            Power Boom's founders recognized that a knowledge gap is preventing many facility operators from setting up the on-site EV charging that they desire and would benefit us all.
                            <div className='fw-bold'>Power Boom was created to close the gap.</div>
                        </h2>
                    </Col>
                </Row>
            </Container>
            <Container className='py-5'>
                <Row className='py-md-5 justify-content-center'>
                    <Col md={8}>
                        <h2 className='fs-48 text-center fw-bold'>The Power Boom team</h2>
                        <p className='text-center'>Power Boom's mission-driven leadership brings decades of experience in electrification and the automotive industry to craft an EV charging roadmap tailored to your goals, location, and site parameters. </p>
                    </Col>
                </Row>
                <Row className='justify-content-evenly'>
                    <Col md={5} className='py-4 py-md-0 text-center'>
                        <img src='/images/Alex.png' width='200px' />
                        <h6 className='mb-0 fw-bold pt-4'>Alexander Boom</h6>
                        <p>Chief Executive Officer</p>
                        <p className='text-start'>
                            After founding and quickly growing a recycling business with the United States Peace Corps, Alex went on to spend nearly a decade electrifying homes and businesses at two of the world’s largest solar companies. Alex brings to Power Boom a deep understanding of the energy landscape and a passion for applying sustainable solutions to global challenges.
                        </p>
                    </Col>
                    <Col md={5} className='py-4 py-md-0 text-center'>
                        <img src={Gina} width='200px' />
                        <h6 className='mb-0 fw-bold pt-4'>Gina Gennette</h6>
                        <p>Co-Executive Chair</p>
                        <p className='text-start'>
                            Gina’s incentive program and payment processing expertise enabled her to found and build a large digital rewards company that manages programs for global auto manufacturers. Power Boom benefits from Gina’s numerous longstanding partnerships with the car industry and experience building profitable customer-facing programs for commercial clients.
                        </p>
                    </Col>
                </Row>
                <Row className='justify-content-evenly py-5 mt-5'>
                    <Col md={2} xs={6} className='py-4 py-md-0 text-center'>
                        <img src={Sushant} width='100%' />
                        <h6 className='mb-0 fw-bold pt-4'>Sushant Vengurlekar</h6>
                        <p>Chief Technical Officer</p>
                    </Col>
                    <Col md={2} xs={6} className='py-4 py-md-0 text-center'>
                        <img src={Dawn} width='100%' />
                        <h6 className='mb-0 fw-bold pt-4'>Dawn Cody</h6>
                        <p>Director of Accounting</p>
                    </Col>
                    <Col md={2} xs={6} className='py-4 py-md-0 text-center'>
                        <img src={Bridget} width='100%' />
                        <h6 className='mb-0 fw-bold pt-4'>Bridget Emery</h6>
                        <p>Director of Human Resources</p>
                    </Col>
                    <Col md={2} xs={6} className='py-4 py-md-0 text-center'>
                        <img src={Makena} width='100%' />
                        <h6 className='mb-0 fw-bold pt-4'>Makena Thurston</h6>
                        <p>Director of Brand</p>
                    </Col>
                </Row>
            </Container>
            <CTA />
            <Footer />
        </>
    );
}

export default About;