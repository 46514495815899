import { Row, Col, Container } from 'react-bootstrap';
import '../GlobalStyle.css';
import { HoverCardShadow, SunEdgeCard, ShadowCardNoHover } from '../styled/PBCards';

import ChargingDemandIcon from '../../assets/icons/ChargingDemandIcon.svg'
import CompetitiveAnalysisIcon from '../../assets/icons/CompetitiveAnalysisIcon.svg'
import SiteHostIcon from '../../assets/icons/SiteHostIcon.svg'
import SiteAssessmentIcon from '../../assets/icons/SiteAssessmentIcon.svg'
import SolutionsReviewIcon from '../../assets/icons/SolutionsReviewIcon.svg'
import IncentiveIcon from '../../assets/icons/IncentiveIcon.svg'
import ProcurementIcon from '../../assets/icons/ProcurementIcon.svg'


import Worplaces from '../../assets/images/Office Parking Lot.png'


const ServicesCards = () => {
    return (
        <>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col sm={6} className="my-2">
                        <HoverCardShadow
                            // href='/services#chargingdemand'
                        >
                            <img src={ChargingDemandIcon} width={38} className="pb-3" />
                            <h6 className='fw-bold'>Charging demand assessment</h6>
                        </HoverCardShadow>
                    </Col>
                    <Col sm={6} className="my-2">
                        <HoverCardShadow
                            // href='/services#competitive'
                        >
                            <img src={CompetitiveAnalysisIcon} width={44} className="pb-3" />
                            <h6 className='fw-bold'>Charging competitive analysis</h6>
                        </HoverCardShadow>
                    </Col>
                    <Col sm={6} className="my-2">
                        <HoverCardShadow
                            // href='/services#sitehostneeds'
                        >
                            <img src={SiteHostIcon} width={28} className="pb-3" />
                            <h6 className='fw-bold'>Site host requirements deep dive</h6>
                        </HoverCardShadow>
                    </Col>
                    <Col sm={6} className="my-2">
                        <HoverCardShadow
                            // href='/services#sitehostassessment'
                        >
                            <img src={SiteAssessmentIcon} width={28} className="pb-3" />
                            <h6 className='fw-bold'>Site assessmnt</h6>
                        </HoverCardShadow>
                    </Col>
                    <Col sm={6} className="my-2">
                        <HoverCardShadow
                            // href='/services#solutions'
                        >
                            <img src={SolutionsReviewIcon} width={38} className="pb-3" />
                            <h6 className='fw-bold'>Charging solutions review</h6>
                        </HoverCardShadow>
                    </Col>
                    <Col sm={6} className="my-2">
                        <HoverCardShadow
                            // href='/services#incentives'
                        >
                            <img src={IncentiveIcon} width={44} className="pb-3" />
                            <h6 className='fw-bold'>Incentive identifiction</h6>
                        </HoverCardShadow>
                    </Col>
                    <Col sm={6} className="my-2">
                        <HoverCardShadow
                            // href='/services#procurement'
                        >
                            <img src={ProcurementIcon} width={42} className="pb-3" />
                            <h6 className='fw-bold'>Procurement guidance</h6>
                        </HoverCardShadow>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const PackageCards = () => {
    return (
        <>
            <Container fluid>
                <Row className='justify-content-center'>
                    <Col md={4} className="my-2">
                        <SunEdgeCard>
                            <h6 className='fs-24 fw-bold'>Strategic Assessor</h6>
                            <p>
                                We'll assess the local demand for EV chargers, how nearby facilities have set up charging, and which financial incentives are available for charging sites in your area.
                            </p>
                        </SunEdgeCard>
                    </Col>
                    <Col md={4} className="my-2">
                        <SunEdgeCard>
                            <h6 className='fs-24 fw-bold'>Project Planner</h6>
                            <p>
                                We'll assess the local demand for EV chargers and how nearby facilities have set up charging. Then, our team will conduct a deep dive into your site's EV charging needs to identify the best hardware and software solutions and find available financial incentives.
                            </p>
                        </SunEdgeCard>
                    </Col>
                    <Col md={4} className="my-2">
                        <SunEdgeCard>
                            <h6 className='fs-24 fw-bold'>Project Executer</h6>
                            <p>
                                We'll assess the local demand for EV chargers and how nearby facilities have set up charging. Then, our team will conduct a deep dive into your site's EV charging needs to identify the best hardware and software solutions and find available financial incentives. Once your preferred solution is selected, we'll review the procurement options with your team and prepare you for bid solicitation.
                            </p>
                        </SunEdgeCard>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const ClientCards = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col xl={4} md={6} className='my-2'>
                        <ShadowCardNoHover>
                            <Row className='align-items-center'>
                                <Col>
                                    <img src='/images/Multifamily Housing.png' width={180} alt='multifamily housing' />
                                </Col>
                                <Col>
                                    <h6>Multi-Family Housing</h6>
                                </Col>
                            </Row>
                        </ShadowCardNoHover>
                    </Col>
                    <Col xl={4} md={6} className='my-2'>
                        <ShadowCardNoHover>
                            <Row className='align-items-center'>
                                <Col>
                                    <img src={Worplaces} width={180} />
                                </Col>
                                <Col>
                                    <h6>Workplaces</h6>
                                </Col>
                            </Row>
                        </ShadowCardNoHover>
                    </Col>
                    <Col xl={4} md={6} className='my-2'>
                        <ShadowCardNoHover>
                            <Row className='align-items-center'>
                                <Col>
                                    <img src='/images/Hotel.png' width={180} alt='Hotels' />
                                </Col>
                                <Col>
                                    <h6>Hotels</h6>
                                </Col>
                            </Row>
                        </ShadowCardNoHover>
                    </Col>
                    <Col xl={4} md={6} className='my-2'>
                        <ShadowCardNoHover>
                            <Row className='align-items-center'>
                                <Col>
                                    <img src='/images/Shopping Center.png' width={180} alt='Shopping Centers' />
                                </Col>
                                <Col>
                                    <h6>Shopping Centers</h6>
                                </Col>
                            </Row>
                        </ShadowCardNoHover>
                    </Col>
                    <Col xl={4} md={6} className='my-2'>
                        <ShadowCardNoHover>
                            <Row className='align-items-center'>
                                <Col>
                                    <img src='/images/Auto Shop.png' width={180} alt='Auto Service Shops' />
                                </Col>
                                <Col>
                                    <h6>Auto-Service Shops</h6>
                                </Col>
                            </Row>
                        </ShadowCardNoHover>
                    </Col>
                    <Col xl={4} md={6} className='my-2'>
                        <ShadowCardNoHover>
                            <Row className='align-items-center'>
                                <Col>
                                    <img src='/images/Sports Stadium.png' width={180} alt='Sports Complexes' />
                                </Col>
                                <Col>
                                    <h6>Sports Stadiums & Event Centers</h6>
                                </Col>
                            </Row>
                        </ShadowCardNoHover>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export { ServicesCards, PackageCards, ClientCards }