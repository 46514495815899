import { Container, Button, Carousel, Row, Col } from "react-bootstrap";
import "../GlobalStyle.css";
import CircleCheck from '../../assets/icons/CircleCheck.png'


const OrderConfirmation = () => {

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="text-center pb-4">
            <img src={CircleCheck} width={36} />
          </Col>
        </Row>
        <Row>
          <Col className="border-bottom">
            <h4 className="fw-bold">
              Order Confirmed!
            </h4>
            <p>
              Your order has been placed and we've sent a confirmation to the email address provided.
            </p>
            <p>
              You'll shortly receive a second email laying out our next steps.
            </p>
            <p>
              We're excited to create a charging plan for your facility!
            </p>
          </Col>
        </Row>
      </Container >
    </>
  );
};

export default OrderConfirmation;
