import { Container, Row, Col } from 'react-bootstrap';
import '../GlobalStyle.css';
import styled from 'styled-components';
import { PWRPrimaryButton } from '../styled/Buttons';
import { ForestEdgeShadowCardNoHover } from '../styled/PBCards';
import { Included } from '../styled/Icons';
import { FeaturesModal } from './PBModal';

const DetailsList = styled.div`
ul {
    font-size: 1.125rem;
    color: #333333;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    padding: .25rem 0rem;
    
    img {
        margin-right: .5rem;
     }
}
`

export const cartAmountKey = 'cart-amount';
export const cartNameKey = 'cart-name';
export const cartIncludeKey = 'cart-include';

const StrategicAssessorCartCard = () => {

    const detailsListData = [
      "Charging Demand Assessment",
      "Charging Competitive Analysis",
      "Incentive Identification",
    ];

    const amount = "$2,440";
    const packageName = "Strategic Assessor";

    saveDataInLocalStorage(amount, packageName, detailsListData);

    return (
        <>
            <Container fluid>
                <ForestEdgeShadowCardNoHover>
                    <Row className='pb-4'>
                        <Col>
                            <div className='package-title'>
                               {packageName}
                                <span>{amount}</span>
                            </div>
                            <p className='color-light-steel fs-16'>Up to 10 hours</p>
                            <p>
                                Best for facility operators assessing the right time and available benefits for setting up on-site EV charging.
                            </p>
                            <PWRPrimaryButton className='d-block' href='/place-your-order'>Confirm Selection</PWRPrimaryButton>
                        </Col>
                    </Row>
                </ForestEdgeShadowCardNoHover >
                <Row className='pt-4 border-top'>
                    <Col>
                        <DetailsList>
                            <p>What's included:</p>
                            <ul>
                                <li>
                                    <Included /> Charging Demand Assessment
                                </li>
                                <li>
                                    <Included /> Charging Competitive Analysis
                                </li>
                                <li>
                                    <Included /> Incentive Identification
                                </li>
                            </ul>
                        </DetailsList>
                        <FeaturesModal />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const ProjectPlannerCartCard = () => {
    const detailsListData = [
        "Charging Demand Assessment",
        "Charging Competitive Analysis",
        "Site Host Needs Deep Dive",
        "Incentive Identification",
        "Solutions Review"
      ];
  
      const amount = "$4,977";
      const packageName = "Project Planner";
  
      saveDataInLocalStorage(amount, packageName, detailsListData);
    return (
        <>
            <Container fluid>
                <ForestEdgeShadowCardNoHover>
                    <Row className='pb-4'>
                        <Col>
                            <div className='package-title'>
                                {packageName}
                                <span>{amount}</span>
                            </div>
                            <p className='color-light-steel fs-16'>Up to 20 hours</p>
                            <p>
                                Best for facility operators wanting an expertly crafted project plan for successfully setting up on-site EV charging.
                            </p>
                            <PWRPrimaryButton className='d-block' href='/place-your-order'>Confirm Selection</PWRPrimaryButton>
                        </Col>
                    </Row>
                </ForestEdgeShadowCardNoHover>
                <Row className='pt-4 border-top'>
                    <Col>
                        <DetailsList>
                            <p>What's included:</p>
                            <ul>
                                <li>
                                    <Included /> Charging Demand Assessment
                                </li>
                                <li>
                                    <Included /> Charging Competitive Analysis
                                </li>
                                <li>
                                    <Included /> Site Host Needs Deep Dive
                                </li>
                                <li>
                                    <Included /> Incentive Identification
                                </li>
                                <li>
                                    <Included /> Solutions Review
                                </li>
                            </ul>
                        </DetailsList>
                        <FeaturesModal />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const ProjectExecuterCartCard = () => {

    const detailsListData = [
        "Charging Demand Assessment",
        "Charging Competitive Analysis",
        "Site Host Needs Deep Dive",
        "Site Assessment",
        "Incentive Identification",
        "Solutions Review",
        "Procurement Guidance"
      ];
  
      const amount = "$14,151";
      const packageName = "Project Executer";
  
      saveDataInLocalStorage(amount, packageName, detailsListData);

    return (
        <>
            <Container fluid>
                <ForestEdgeShadowCardNoHover>
                    <Row className='pb-4'>
                        <Col>
                            <div className='package-title'>
                                {packageName}
                                <span>{amount}</span>
                            </div>
                            <p className='color-light-steel fs-16'>Up to 60 hours</p>
                            <p>
                                Best for facility operators ready to create and execute a project plan to successfully begin EV charging on-site.
                            </p>
                            <PWRPrimaryButton className='d-block' href='/place-your-order'>Confirm Selection</PWRPrimaryButton>
                        </Col>
                    </Row>
                </ForestEdgeShadowCardNoHover>
                <Row className='pt-4 border-top'>
                    <Col>
                        <DetailsList>
                            <p>What's included:</p>
                            <ul>
                                <li>
                                    <Included />  Charging Demand Assessment
                                </li>
                                <li>
                                    <Included /> Charging Competitive Analysis
                                </li>
                                <li>
                                    <Included />  Site Host Needs Deep Dive
                                </li>
                                <li>
                                    <Included /> Site Assessment
                                </li>
                                <li>
                                    <Included />  Solutions Review
                                </li>
                                <li>
                                    <Included /> Incentive Identification
                                </li>
                                <li>
                                    <Included /> Procurement Guidance
                                </li>
                            </ul>
                        </DetailsList>
                        <FeaturesModal />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const saveDataInLocalStorage = (amount:string, packageName: string, detailsListData: string[]) =>{
      localStorage.setItem(cartAmountKey, amount);
      localStorage.setItem(cartNameKey, packageName);
      localStorage.setItem(cartIncludeKey, JSON.stringify(detailsListData));
}

export { StrategicAssessorCartCard, ProjectPlannerCartCard, ProjectExecuterCartCard }