import { Container, Row, Col } from "react-bootstrap";
import "../GlobalStyle.css";
import { Form, Input, Select, FormInstance } from "antd";
import { Istep1Final, Istep2Final } from "./types";
import PaymentStep1 from "./PaymentStep1";
import { Istep1 } from "./types";
import PaymentStep2 from "./PaymentStep2";

type Iprops = {
  formState: {
    formStep1: Istep1Final;
    formStep2: Istep2Final;
    setFormValues1: React.Dispatch<React.SetStateAction<Istep1 | undefined>>;
    setFormValues2: React.Dispatch<React.SetStateAction<Istep2Final>>;
  };
  next: () => void;
  prev: () => void;
  onFinish: (form: FormInstance) => void;
  form?: FormInstance;
};

const PaymentSummary = (props: Iprops) => {
    const { next, form, onFinish, prev, formState } = props;
  return <>

<PaymentStep1 disabled onFinish={onFinish} form={form} formState={{ formStep1: formState.formStep1, setFormValues1: formState.setFormValues1 }} next={next} />
<PaymentStep2 disabled onFinish={onFinish} form={form} formState={{ formStep1: formState.formStep1, formStep2: formState.formStep2, setFormValues2: formState.setFormValues2 }} next={next} prev={prev} />
  </>;
};

export default PaymentSummary;
