import { Accordion } from 'react-bootstrap';
import '../GlobalStyle.css';
import styled from 'styled-components';

import { PWBAccordionStyled } from '../styled/PWRAccordion';


const PackageDetailsFAQ = () => {
    return (
        <>
            <PWBAccordionStyled>
                <Accordion >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>How quickly will I receive my service?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                <strong>Strategic Assessor:</strong> Less than 1 week
                            </p>
                            <p>
                                <strong>Project Planner:</strong> 1-2 Weeks
                            </p>
                            <p>
                                <strong>Project Executor:</strong> Typically 2-3 Weeks. We cater to our client’s availability.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header>What is Power Boom’s cancellation policy?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                            Online orders can be canceled within 24 hours. Call us if you need to cancel or adjust your order 1-858-799-4583.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>What types of electric vehicle chargers can Power Boom help us assess and set up?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Level 2, grid-connected
                            </p>
                            <p>
                                Level 2, off-grid solar-powered
                            </p>
                            <p>
                                Direct Current Fast Chargers (DCFC), stationary
                            </p>
                            <p>
                                Direct Current Fast Chargers (DCFC), portable
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                        <Accordion.Header>What types of facilities does Power Boom serve?</Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Any facility that wants to offer EV charging as an on-site amenity.
                            </p>
                            <p>
                                Our clients typically operate facilities where electric vehicle drivers work, sleep, shop, and play. Including workplaces, warehouses, hotels, multi-family housing, shopping centers, auto shops, gyms, wineries, stadiums, and event centers.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
            </PWBAccordionStyled >
        </>
    );
}

export { PackageDetailsFAQ }