import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import { ScrollToTop } from '../src/components/ScrollToTop'
import Home from "./pages/Home";
import Services from "./pages/Services";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import { ApolloClientProvider } from "./apollo/ApolloClientProvider";
import ServicePackages from "./pages/ServicePackages";

import CartStrategic from './pages/CartStrategic';
import CartExecuter from "./pages/CartExecuter";
import CartPlanner from "./pages/CartPlanner";
import PlaceOrder from "./pages/PlaceOrder";

function App() {
  return (
    <ApolloClientProvider>
      
      <BrowserRouter>
			<ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/service-packages" element={<ServicePackages />} />

          <Route path="/cart-strategic-assessor" element={<CartStrategic />} />
          <Route path="/cart-project-planner" element={<CartPlanner />} />
          <Route path="/cart-project-executer" element={<CartExecuter />} />
          <Route path="/place-your-order" element={<PlaceOrder />} />          

          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </ApolloClientProvider>
  );
}

export default App;
