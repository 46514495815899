import { Container, Row, Col } from 'react-bootstrap';
import { Navigation, ServicesNavigation } from '../components/Layout/Navigation';
import CTA from '../components/Layout/CTA';
import Footer from '../components/Layout/Footer';
import styled from 'styled-components';
import { PWRPrimaryButton, PWRSecondaryButton } from '../components/styled/Buttons';
import { Helmet } from 'react-helmet';

import ServicesSplash from '../assets/images/Services - Splash.png';
import ChargingDemand from '../assets/images/chargingDA.png';
import CompetitiveAnalysis from '../assets/images/chargingCA.png';
import SiteHostNeeds from '../assets/images/SiteHostNeeds.png';
import SiteAssessment from '../assets/images/sitehost.png';
import Solutions from '../assets/images/Solutions.png';
import Incentives from '../assets/images/incentive.png';
import Procurement from '../assets/images/progud.png';
import { useMediaQuery } from 'react-responsive';
import '../components/GlobalStyle.css';
import { FadeLoad, FadeScroll } from '../components/styled/Motion.styled';

const SunBar = styled.div`
    padding-left: 1.5rem;
    border-left: 10px solid #FFCF01;
    margin-bottom: 2rem;
    scroll-padding-top: 20rem;

`

const AnchorSection = styled.div`
    scroll-margin-top: 8rem;
`


const Services = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 }); 
    return (
        <>
            { isMobile ? <MobileServices/> : <DesktopServices/>}
        </>
    );
}

const DesktopServices = () => {
    return (
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Power Boom | Services</title>
                <link rel="canonical" href="https://pwrboom.com/services" />
            </Helmet>
            <Navigation />
            <Container fluid className='px-0'>
                <ServicesNavigation />
            </Container>
            <Container fluid className='vh-75 pt-5'>
                <Row className='ps-md-5 justify-content-between align-items-center'>
                    <Col md={5} className='pt-5 pt-md-0'>
                        <FadeLoad>
                            <h1 className='fs-60'>Our <strong>Services</strong></h1>
                            <p className='my-4 pb-4'>
                                Power Boom offers a range of services that help you determine when it's time to start offering EV charging, understand which charging stations are the best for your facility, and how to set up charging at your site.
                            </p>
                            <PWRPrimaryButton href='/service-packages'>Pick a Service Package</PWRPrimaryButton>
                        </FadeLoad>
                    </Col>
                    <Col md={6} className='pt-5 pt-md-0'>
                        <img src={ServicesSplash} width='100%' />
                    </Col>
                </Row>
            </Container>
            <AnchorSection id='chargingdemand'>
                <Container fluid className='bg-silver pt-5'>
                    <Row className='align-items-center pt-5'>
                        <Col md={5}>
                            <img src={ChargingDemand} width='100%' />
                        </Col>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Charging demand assessment</h3>
                                    <p>
                                    We assess drivers' demand for EV charging in your area to map into the local charging landscape. Your assessment provides visibility into how many electric vehicles and chargers are currently in your area, which types of EVs people are driving, and what the local community is saying about EV driving and charging needs.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See analysis details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='competitive'>
                <Container fluid className='vh-75'>
                    <Row className='align-items-center'>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Charging competitive analysis</h3>
                                    <p>
                                    We competitively analyze charger offerings in your area so you can determine when and how to best set up on-site charging. Your competitive analysis will reveal which nearby facilities offer EV charging, how the ratio of drivers to chargers compares to other regions, where free public charging is available, how much drivers pay for paid charging sessions, and the types of EV chargers available in your area.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }}>
                            <img src={CompetitiveAnalysis} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='sitehostneeds'>
                <Container fluid className='bg-silver pt-5'>
                    <Row className='align-items-center pt-5'>
                        <Col md={5}>
                            <img src={SiteHostNeeds} width='100%' />
                        </Col>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Site host needs deep dive</h3>
                                    <p>
                                        We dive deep into your team's needs and goals for offering on-site EV charging to identify charging solutions that fit all your stakeholders and ensure organizational alignment. We'll uncover the reasons to provide charging at your site, who and how many drivers you want to power, the vehicle types you'll charge, what times of the day charging will occur, your charging budget and return on investment, and the charging stations necessary capabilities.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See analysis details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='sitehostassessment'>
                <Container fluid className='vh-75'>
                    <Row className='align-items-center'>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Site Host Assessment</h3>
                                    <p>
                                    We assess your site so you understand where and how to set up charging stations at your facility. We'll determine where to locate charging stations to meet your needs while minimizing electrical and construction projects, which parking spaces can access charging, if electric upgrades are necessary, and the charging station features required to create the desired charging experience.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }}>
                            <img src={SiteAssessment} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='solutions'>
                <Container fluid className='bg-silver pt-5'>
                    <Row className='align-items-center pt-5'>
                        <Col md={5}>
                            <img src={Solutions} width='100%' />
                        </Col>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Solutions Review</h3>
                                    <p>
                                        We craft solution packages that achieve your on-site charging goals. We'll show the available EV charging hardware and software solutions, explain why they are the right fit for you, calculate the package costs, chart charging revenue generation, and explain the impact on your energy bills.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='incentives'>
                <Container fluid className='vh-75'>
                    <Row className='align-items-center'>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Incentive identification</h3>
                                    <p>
                                        We identify available financial incentives so you know how much money you can receive for offering on-site EV charging. We research and identify the local, state, and federal tax credits, rebates, and grants available for EV chargers and alternative fuel production in your area. Then, break out the value of incentives, their requirements, and how to apply for them.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }}>
                            <img src={Incentives} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='procurement'>
                <Container fluid className='bg-silver vh-75 pt-5 pt-md-0'>
                    <Row className='align-items-center'>
                        <Col md={5}>
                            <img src={Procurement} width='100%' />
                        </Col>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Procurement guidance</h3>
                                    <p>
                                        We guide your team through procuring the EV charging hardware and software that will enable you to begin charging on-site. We'll explain the differences between hardware and software suppliers to determine the right fit, prepare your team to solicit bids and connect you with our supplier contacts at companies of interest.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <CTA />
            <Footer />
        </>
    )
}

const MobileServices = () => {
    return (
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Power Boom | Services</title>
                <link rel="canonical" href="https://pwrboom.com/services" />
            </Helmet>
            <Navigation />
            <Container fluid className='vh-90 pt-6'>
                <Row className='ps-md-5 justify-content-between align-items-center'>
                    <Col md={5} className='pt-1 pt-md-0'>
                        <FadeLoad>
                            <h1 className='fs-60'>Our <strong>Services</strong></h1>
                            <p className='my-4 pb-4'>
                                Power Boom offers a range of services that help you determine when it's time to start offering EV charging, understand which charging stations are the best for your facility, and how to set up charging at your site.
                            </p>
                            <PWRPrimaryButton href='/service-packages'>Pick a Service Package</PWRPrimaryButton>
                        </FadeLoad>
                    </Col>
                    <Col md={6} className='pt-5 pt-md-0'>
                        <img src={ServicesSplash} width='100%' />
                    </Col>
                </Row>
            </Container>
            <AnchorSection id='chargingdemand'>
                <Container fluid className='bg-silver pt-6'>
                    <Row className='align-items-center pt-5'>
                        
                        <Col md={{ span: 5, offset: 1 }} className='pt-1 py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Charging demand assessment</h3>
                                    <p>
                                    We assess drivers' demand for EV charging in your area to map into the local charging landscape. Your assessment provides visibility into how many electric vehicles and chargers are currently in your area, which types of EVs people are driving, and what the local community is saying about EV driving and charging needs.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See analysis details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={5}>
                            <img src={ChargingDemand} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='competitive'>
                <Container fluid className='vh-75'>
                    <Row className='align-items-center'>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Charging competitive analysis</h3>
                                    <p>
                                    We competitively analyze charger offerings in your area so you can determine when and how to best set up on-site charging. Your competitive analysis will reveal which nearby facilities offer EV charging, how the ratio of drivers to chargers compares to other regions, where free public charging is available, how much drivers pay for paid charging sessions, and the types of EV chargers available in your area.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }}>
                            <img src={CompetitiveAnalysis} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='sitehostneeds'>
                <Container fluid className='bg-silver pt-6'>
                    <Row className='align-items-center pt-5'>
                       
                        <Col md={{ span: 5, offset: 1 }} className='pt-0 py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Site host needs deep dive</h3>
                                    <p>
                                        We dive deep into your team's needs and goals for offering on-site EV charging to identify charging solutions that fit all your stakeholders and ensure organizational alignment. We'll uncover the reasons to provide charging at your site, who and how many drivers you want to power, the vehicle types you'll charge, what times of the day charging will occur, your charging budget and return on investment, and the charging stations necessary capabilities.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See analysis details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={5}>
                            <img src={SiteHostNeeds} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='sitehostassessment'>
                <Container fluid className='vh-75'>
                    <Row className='align-items-center'>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Site Host Assessment</h3>
                                    <p>
                                    We assess your site so you understand where and how to set up charging stations at your facility. We'll determine where to locate charging stations to meet your needs while minimizing electrical and construction projects, which parking spaces can access charging, if electric upgrades are necessary, and the charging station features required to create the desired charging experience.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }}>
                            <img src={SiteAssessment} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='solutions'>
                <Container fluid className='bg-silver pt-6'>
                    <Row className='align-items-center pt-5'>
                        
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0 pt-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Solutions Review</h3>
                                    <p>
                                        We craft solution packages that achieve your on-site charging goals. We'll show the available EV charging hardware and software solutions, explain why they are the right fit for you, calculate the package costs, chart charging revenue generation, and explain the impact on your energy bills.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={5}>
                            <img src={Solutions} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='incentives'>
                <Container fluid className='vh-75'>
                    <Row className='align-items-center'>
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Incentive identification</h3>
                                    <p>
                                        We identify available financial incentives so you know how much money you can receive for offering on-site EV charging. We research and identify the local, state, and federal tax credits, rebates, and grants available for EV chargers and alternative fuel production in your area. Then, break out the value of incentives, their requirements, and how to apply for them.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }}>
                            <img src={Incentives} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <AnchorSection id='procurement'>
                <Container fluid className='bg-silver vh-75 pt-6 pt-md-0'>
                    <Row className='align-items-center'>
                       
                        <Col md={{ span: 5, offset: 1 }} className='py-5 py-md-0 pt-4'>
                            <FadeScroll>
                                <SunBar>
                                    <h3 className='fw-bold'>Procurement guidance</h3>
                                    <p>
                                        We guide your team through procuring the EV charging hardware and software that will enable you to begin charging on-site. We'll explain the differences between hardware and software suppliers to determine the right fit, prepare your team to solicit bids and connect you with our supplier contacts at companies of interest.
                                    </p>
                                </SunBar>
                                <PWRSecondaryButton className='ms-4' href='/service-packages'>See Review Details</PWRSecondaryButton>
                            </FadeScroll>
                        </Col>
                        <Col md={5}>
                            <img src={Procurement} width='100%' />
                        </Col>
                    </Row>
                </Container>
            </AnchorSection>
            <CTA />
            <Footer />
        </>
    )
}

export default Services;