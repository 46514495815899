import { Container, Row, Col } from 'react-bootstrap';
import '../GlobalStyle.css';
import styled from 'styled-components';
import { PWRPrimaryButton, PWRSecondaryButton } from '../styled/Buttons';
import { PackageCard } from '../styled/PBCards';
import { Included } from '../styled/Icons';


const StrategicAssessorCard = () => {
    return (
        <>
            <PackageCard>
                <Container fluid>
                    <Row className='pb-4'>
                        <Col>
                            <div className='package-title'>
                                Strategic Assessor
                                <span>$2,440</span>
                            </div>
                            <p className='color-light-steel fs-16'>Up to 10 hours</p>
                            <p>
                                Best for facility operators assessing the right time and available benefits for setting up on-site EV charging.
                            </p>
                            <PWRPrimaryButton className='d-block' href='/cart-strategic-assessor'>Select</PWRPrimaryButton>
                        </Col>
                    </Row>
                    <Row className='pt-4 border-top'>
                        <Col>
                            <p>What's included:</p>
                            <ul>
                                <li>
                                    <Included /> Charging Demand Assessment
                                </li>
                                <li>
                                    <Included /> Charging Competitive Analysis
                                </li>
                                <li>
                                    <Included /> Incentive Identification
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </PackageCard >
        </>
    );
}

const ProjectPlannerCard = () => {
    return (
        <>
            <PackageCard>
                <Container fluid>
                    <Row className='pb-4'>
                        <Col>
                            <div className='package-title'>
                                Project Planner
                                <span>$4,977</span>
                            </div>
                            <p className='color-light-steel fs-16'>Up to 20 hours</p>
                            <p>
                                Best for facility operators wanting an expertly crafted project plan for successfully setting up on-site EV charging.
                            </p>
                            {/* <PWRPrimaryButton className='d-block' href='cart-project-planner'>Select</PWRPrimaryButton> */}
                            <div className='d-flex justify-content-between'>
                                <PWRPrimaryButton className='w-50 me-1 d-inline-block' href='/cart-project-planner'>Select</PWRPrimaryButton>
                                <PWRSecondaryButton className='w-50 d-inline-block' href='/contact-us'>Speak to Sales</PWRSecondaryButton>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-4 border-top'>
                        <Col>
                            <p>What's included:</p>
                            <ul>
                                <li>
                                    <Included /> Charging Demand Assessment
                                </li>
                                <li>
                                    <Included /> Charging Competitive Analysis
                                </li>
                                <li>
                                    <Included /> Site Host Needs Deep Dive
                                </li>
                                <li>
                                    <Included /> Incentive Identification
                                </li>
                                <li>
                                    <Included /> Solutions Review
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </PackageCard >
        </>
    );
}

const ProjectExecuterCard = () => {
    return (
        <>
            <PackageCard>
                <Container fluid>
                    <Row className='pb-4'>
                        <Col>
                            <div className='package-title'>
                                Project Executer
                                <span>$14,151</span>
                            </div>
                            <p className='color-light-steel fs-16'>Up to 60 hours</p>
                            <p>
                                Best for facility operators ready to create and execute a project plan to successfully begin EV charging on-site.
                            </p>
                            <div className='d-flex justify-content-between'>
                                <PWRPrimaryButton className='w-50 me-1 d-inline-block' href='/cart-project-executer'>Select</PWRPrimaryButton>
                                <PWRSecondaryButton className='w-50 d-inline-block' href='/contact-us'>Speak to Sales</PWRSecondaryButton>
                            </div>
                        </Col>
                    </Row>
                    <Row className='pt-4 border-top'>
                        <Col>
                            <p>What's included:</p>
                            <ul>
                                <li>
                                    <Included />  Charging Demand Assessment
                                </li>
                                <li>
                                    <Included /> Charging Competitive Analysis
                                </li>
                                <li>
                                    <Included />  Site Host Needs Deep Dive
                                </li>
                                <li>
                                    <Included /> Site Assessment
                                </li>
                                <li>
                                    <Included />  Solutions Review
                                </li>
                                <li>
                                    <Included /> Incentive Identification
                                </li>
                                <li>
                                    <Included /> Procurement Guidance
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </PackageCard >
        </>
    );
}

export { StrategicAssessorCard, ProjectPlannerCard, ProjectExecuterCard }