import { Container, Row, Col } from "react-bootstrap";
import "../GlobalStyle.css";
import { Form, Input, Select, FormInstance} from "antd";
import { Istep1Final, Istep2Final } from "./types";


const { TextArea } = Input;

type Iprops = {
  formState: {
    formStep1: Istep1Final;
    formStep2: Istep2Final;
    setFormValues2: React.Dispatch<React.SetStateAction<Istep2Final>>;
  };
  next: () => void;
  prev: () => void;
  onFinish: (form: FormInstance) => void
	form?: FormInstance
  disabled?: boolean
};

const PaymentStep2 = (props: Iprops) => {
  const { next, form, onFinish, prev, disabled } = props;
  return (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Container fluid>
          <Row>
            <Col className="border-bottom">
              <h5 className="fw-bold">
                Facility Information
              </h5>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col xs={12}>
              <Form.Item
                label="Facility Type"
                name="facility"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter the facility type",
                  },
                ]}
              >
                <Select
                options={[
                  { value: 'mfh', label: 'Multi- family Housing' },
                  { value: 'work', label: 'Workplace' },
                  { value: 'hotel', label: 'Hotel' },
                  { value: 'shopping', label: 'Shopping Center' },                  
                  { value: 'autoservice', label: 'Auto-Service' },
                  { value: 'stadium', label: 'Stadium' },
                  { value: 'event', label: 'Event Center' },                  
                  { value: 'winery', label: 'Winery' },
                  { value: 'other', label: 'Other' },

                ]}
                disabled={disabled}/>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Street Address"
                name="street"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter the facility's street address",
                  },
                ]}
              >
                <Input disabled={disabled}/>
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please enter the city where the facility is located",
                  },
                ]}
              >
                <Input disabled={disabled}/>
              </Form.Item>
            </Col>
            <Col sm={3}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: "Please enter the state where the facility is located",
                  },
                ]}
              >
                <Input disabled={disabled}/>
              </Form.Item>
            </Col>
            <Col sm={3}>
              <Form.Item
                label="Zip"
                name="zip"
                rules={[
                  {
                    required: true,
                    message: "Please enter zip code",
                  },
                ]}
              >
                <Input disabled={disabled}/>
              </Form.Item>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default PaymentStep2;
