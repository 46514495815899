import { Table } from 'react-bootstrap';
import '../GlobalStyle.css';
import styled from 'styled-components';

import { Included } from '../styled/Icons';

import { PWRSecondaryButton } from '../styled/Buttons';
import { useMediaQuery } from 'react-responsive';

const PackageTable = styled.div`

.assessarea {
    font-weight: 600;
    color: #707070;
}
    
    tbody td {
        text-align: center;
    }

    tbody th {
        font-weight: normal;
    }

    @media (max-width: 767px) {
        th, td {
          white-space: nowrap;
          max-width: 100px;
        }
      }

`

const PackageDetailsTable = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 }); 
    return (
        <>
           {isMobile ? <MobilePackageDetails/> : <DestopPackageDetails/>}
        </>
    );
}

const DestopPackageDetails = () => {
    return (
        <PackageTable>
        <div>
            <Table responsive>
                <thead className='text-center'>
                    <tr>
                        <th className='text-start fs-36 fw-normal'>Package Details</th>
                        <th>
                            Strategic Assessor
                            <div className='my-3'>
                                <PWRSecondaryButton className='fs-6' href='/cart-strategic-assessor'>Select</PWRSecondaryButton>
                            </div>
                        </th>
                        <th>
                            Project Planner
                            <div className='my-3'>
                                <PWRSecondaryButton className='fs-6' href='/cart-project-planner'>Select</PWRSecondaryButton>
                            </div>
                        </th>
                        <th>
                            Project Executor
                            <div className='my-3'>
                                <PWRSecondaryButton className='fs-6' href='/cart-project-executer'>Select</PWRSecondaryButton>
                            </div>
                        </th>
                    </tr>
                </thead>
                <thead id='demand'>
                    <tr>
                        <th colSpan={4} className='bg-light'>Charging Demand Assessment</th>
                    </tr>
                    <tr>
                        <td colSpan={4} className='assessarea'>Assessment Area</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            # of chargers in area
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            # of EVs in area
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            # EVs per capita
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Most commonly driven EVs
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Local drivers opinions on EV charging
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Local businesses opinions on EV charging
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Local officials opinions on EV charging
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='competitive'>
                    <tr>
                        <th colSpan={4} className='bg-light'>Charging Competititve Analysis</th>
                    </tr>
                    <tr>
                        <td colSpan={4} className='assessarea'>Assessment Area</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Which similar facilities offer EV charging
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Graph of local EV charging sites by facility type
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Comparitive density of EV chargers to drivers
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Where free public charging is locally available
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Local prices of charging sessions ($/kWh)
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Graph of local charging stations by charger type
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='sitehostneeds'>
                    <tr>
                        <th colSpan={4} className='bg-light'>Site Host Needs Deep Dive</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Site host charging requirements survey
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host charging requirements interview
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host charging goals survey
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host charging goals interview
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host stakeholders survey
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host stakeholders interview
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='siteassessment'>
                    <tr>
                        <th colSpan={4} className='bg-light'>Site Assessment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Virtual assessment
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            On-site assessment
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify available charging station locations
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify parking spaces capable of charging access
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify available electrical connections
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Summary of required electrical upgrades
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Summary of required installation construction
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead>
                    <tr>
                        <th colSpan={4} className='bg-light'>Solutions Review</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Map of best locations to install charging stations
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify capable charging station hardware
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify capable charge management software
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Calculation of hardware and software costs
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Map of parking spots getting charging access
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Forecast of charging station utilization
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Calculation of impact on facility's electric bills
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Calculation of potential revenue from charging sessions
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Define steps to set up on-site charging
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='incentives'>
                    <tr>
                        <th colSpan={4} className='bg-light'>Incentive Identification</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Identify city incentives
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify utility company incentives
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify state incentives
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify federal incentives
                        </th>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='procurement'>
                    <tr>
                        <th colSpan={4} className='bg-light'>Procurement Guidance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Compare and recommend charging station hardware
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Compare and recommend charging management software
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Introduction to supplier contacts
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Prep site host for bid solicitation and review
                        </th>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div >
    </PackageTable>
    )
}

const MobilePackageDetails = () => {
    return (
        <PackageTable>
        <div>
            <Table responsive>
                <thead className='text-center'>
                    <tr>
                        <th className='text-start fs-36 fw-normal'>Package Details</th>
                    </tr>
                    <tr>
                        <th>
                            Strategic <br/> Assessor 
                            <div className='my-3'>
                                <PWRSecondaryButton className='fs-6' href='/cart-strategic-assessor'>Select</PWRSecondaryButton>
                            </div>
                        </th>
                        <th>
                            Project<br/> Planner
                            <div className='my-3'>
                                <PWRSecondaryButton className='fs-6' href='/cart-project-planner'>Select</PWRSecondaryButton>
                            </div>
                        </th>
                        <th>
                            Project<br/> Executor
                            <div className='my-3'>
                                <PWRSecondaryButton className='fs-6' href='/cart-project-executer'>Select</PWRSecondaryButton>
                            </div>
                        </th>
                    </tr>
                </thead>
                <thead id='demand'>
                    <tr>
                        <th colSpan={4} className='text-center bg-light'>Charging Demand Assessment</th>
                    </tr>
                    <tr>
                        <td colSpan={4} className='assessarea'>Assessment Area</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            # of chargers in area
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            # of EVs in area
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            # EVs per capita
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Most commonly driven EVs
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Local drivers opinions on EV charging
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Local businesses opinions on EV charging
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Local officials opinions on EV charging
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='competitive'>
                    <tr>
                        <th colSpan={4} className='text-center bg-light'>Charging Competititve Analysis</th>
                    </tr>
                    <tr>
                        <td colSpan={4} className='assessarea'>Assessment Area</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Which similar facilities offer EV charging
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Graph of local EV charging sites by facility type
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Comparitive density of EV chargers to drivers
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Where free public charging is locally available
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Local prices of charging sessions ($/kWh)
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Graph of local charging stations by charger type
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='sitehostneeds'>
                    <tr>
                        <th colSpan={4} className='text-center bg-light'>Site Host Needs Deep Dive</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Site host charging requirements survey
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host charging requirements interview
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host charging goals survey
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host charging goals interview
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host stakeholders survey
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Site host stakeholders interview
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='siteassessment'>
                    <tr>
                        <th colSpan={4} className='text-center bg-light'>Site Assessment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Virtual assessment
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            On-site assessment
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify available charging station locations
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify parking spaces capable of charging <br/> access
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify available electrical connections
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Summary of required electrical upgrades
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Summary of required installation construction
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead>
                    <tr>
                        <th colSpan={4} className='text-center bg-light'>Solutions Review</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Map of best locations to install charging stations
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify capable charging station hardware
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify capable charge management software
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Calculation of hardware and software costs
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Map of parking spots getting charging access
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Forecast of charging station utilization
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Calculation of impact on facility's electric bills
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Calculation of potential revenue from charging <br/>sessions
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Define steps to set up on-site charging
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='incentives'>
                    <tr>
                        <th colSpan={4} className='text-center bg-light'>Incentive Identification</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Identify city incentives
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify utility company incentives
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify state incentives
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Identify federal incentives
                        </th>
                        </tr>
                    <tr>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>

                <thead id='procurement'>
                    <tr>
                        <th colSpan={4} className='text-center bg-light'>Procurement Guidance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>
                            Compare and recommend charging station <br/> hardware
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Compare and recommend charging management<br/> software
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Introduction to supplier contacts
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Prep site host for bid solicitation and review
                        </th>
                        </tr>
                    <tr>
                        <td>
                            -
                        </td>
                        <td>
                            -
                        </td>
                        <td>
                            <Included />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div >
    </PackageTable>
    )
}

export { PackageDetailsTable }