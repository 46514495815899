import { Container, Row, Col } from 'react-bootstrap';
import '../components/GlobalStyle.css';
import { Navigation } from '../components/Layout/Navigation';
import CTA from '../components/Layout/CTA';
import Footer from '../components/Layout/Footer';
import { ClientCards, PackageCards, ServicesCards } from '../components/Home/HomeCards';
import { Helmet } from 'react-helmet';
import { PWRPrimaryButton, PWRSecondaryButton } from '../components/styled/Buttons';
import HomeSplash from '../assets/images/Home - Splash.png';
import { FadeLoad, FadeScroll } from '../components/styled/Motion.styled';


const Home = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Power Boom | EV Charging. Simplified.</title>
                <link rel="canonical" href="https://pwrboom.com/" />
                <meta name='description' content='Customized EV charging for your facility' />
            </Helmet>
            <Navigation />
            <Container fluid className='vh-75'>
                <Row className='ps-md-4 justify-content-between align-items-center'>
                    <Col md={5}>
                        <FadeLoad>
                            <h1 className='text-uppercase fs-70'>We make electric vehicle charging simple</h1>
                            <h2 className='fs-20 my-4 pb-4'>Power Boom's assessments and consultants guide you through successful set up and management of electric vehicle charging at your facility.</h2>
                            <PWRPrimaryButton href='service-packages'>Get Started</PWRPrimaryButton>
                        </FadeLoad>
                    </Col>
                    <Col md={5} className='pt-5 pt-md-0'>
                        <img src={HomeSplash} width='100%' />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='bg-sun vh-50 text-center'>
                <Row className='justify-content-center'>
                    <Col lg={6} xxl={8}>
                        <h3>
                            We expertly analyze the local charging landscape, assess your team's charging needs and site's potential, and craft a customized EV charging roadmap for your facility.
                        </h3>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='vh-75 py-5'>
                <Row className='justify-content-evenly align-items-center'>
                    <Col md={5}>
                        <FadeScroll>
                            <h3 className='fs-60'>
                                Our <strong>Services</strong>
                            </h3>
                            <p className='my-4 pb-4'>
                                Power Boom offers a suite of services that enable you to determine when it's the right time to install EV charging stations, understand the charging hardware and software that are the right fit, and begin charging EVs on-site.
                            </p>
                            <PWRSecondaryButton href='/services'>See Service Details</PWRSecondaryButton>
                        </FadeScroll>
                    </Col>
                    <Col md={6} className='pt-5 pt-md-0'>
                        <ServicesCards />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='vh-75 bg-silver py-5'>
                <Row className='justify-content-center text-center pt-5'>
                    <Col md={8}>
                        <FadeScroll>
                            <h3 className='fs-48'>
                                No matter where you are on the path to setting up EV charging, <strong>we're here to help.</strong>
                            </h3>
                        </FadeScroll>
                    </Col>
                </Row>
                <Row className='justify-content-center text-center pb-5'>
                    <Col lg={4}>
                        <p className='my-4 pb-2'>
                            Power Boom's 3 service packages are designed to take facility operators from assessment to execution.
                        </p>
                        <PWRPrimaryButton href='/service-packages'>Compare Package Details</PWRPrimaryButton>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PackageCards />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='vh-75 py-5'>
                <Row className='justify-content-center align-items-center text-center pb-5'>
                    <Col md={8} xxl={6}>
                        <FadeScroll>
                            <h3 className='fs-48'>
                                Our <strong>Clients</strong>
                            </h3>
                            <p className='my-4 pb-2'>
                            From apartment buildings to stadiums and everywhere in between, we identify the charging solutions that are just right for your facility and needs.
                            </p>
                        </FadeScroll>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ClientCards />
                    </Col>
                </Row>
            </Container >
            <CTA />
            <Footer />
        </>
    );
}

export default Home;