import { Container, Row, Col } from 'react-bootstrap';
import CTA from '../components/Layout/CTA';
import Footer from '../components/Layout/Footer';
import { Helmet } from 'react-helmet';
import '../components/GlobalStyle.css';
import styled from 'styled-components';
import { CartNavigation } from '../components/Layout/CartNavigation';
import { ForestEdgeShadowCardNoHover } from '../components/styled/PBCards';
import PaymentStepper from '../components/PaymentCart/PaymentStepper';
import { Included } from '../components/styled/Icons';
import { cartAmountKey, cartIncludeKey, cartNameKey } from '../components/PaymentCart/ServicePackageCartCards';

const PackageName = styled.div`
    font-size: 1.125rem;
    font-weight: bold;
    color: #333333;
    display: flex;
    justify-content: space-between;
`

const PackageList = styled.div`
ul {
    font-size: 1.125rem;
    color: #333333;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    padding: .25rem 0rem;
    
    img {
        margin-right: .5rem;
     }
}
`

const PlaceOrder = () => {
    const includedItems:any = localStorage.getItem(cartIncludeKey) || []
    const name = localStorage.getItem(cartNameKey) || ""
    const amount = localStorage.getItem(cartAmountKey) || ""
    const renderData = JSON.parse(includedItems).map((item: string) => (
        <li>
          <Included /> {item}
        </li>
      ));
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Place Your Order</title>
                <link rel="canonical" href="https://pwrboom.com/place-your-order" />
            </Helmet>
            <CartNavigation />
            <Container fluid className='pb-5'>
                <Row className='py-5 justify-content-center text-center'>
                    <Col lg={7}>
                        <h1 className='fs-36 fw-normal'>Place your order</h1>
                    </Col>
                </Row>
                <Row>
                </Row>
                <Row className='justify-content-center'>
                    <Col xxl={5} lg={5} md={7} className='my-2'>
                        <ForestEdgeShadowCardNoHover>
                            <PaymentStepper />
                        </ForestEdgeShadowCardNoHover>
                    </Col>
                    <Col lg={3} md={5} className='pt-3'>
                        <p className='border-bottom'>
                            Order Summary
                        </p>
                        <PackageName>{name}<span>{amount}</span></PackageName>
                        <PackageList>
                            <ul>
                                {renderData}
                            </ul>
                        </PackageList>
                    </Col>
                </Row>
            </Container >
            <CTA />
            <Footer />
        </>
    );
}

export default PlaceOrder;