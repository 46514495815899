import { Container, Row, Col } from 'react-bootstrap';
import { Navigation } from '../components/Layout/Navigation';
import Footer from '../components/Layout/Footer';
import { ProjectExecuterCard, ProjectPlannerCard, StrategicAssessorCard } from '../components/ServicePackages/ServicePackageCards';
import { Helmet } from 'react-helmet';
import '../components/GlobalStyle.css';
import { PackageDetailsTable } from '../components/ServicePackages/PackageDetailsTable';
import { PackageDetailsFAQ } from '../components/ServicePackages/PackageDetailsFAQ';
import { PWRPrimaryButtonDark } from '../components/styled/Buttons';
import { FadeLoad } from '../components/styled/Motion.styled';

const ServicePackages = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Power Boom | Service Packages</title>
                <link rel="canonical" href="https://pwrboom.com/service-packages" />
            </Helmet>
            <Navigation />
            <Container>
                <Row className='py-5 text-center'>
                    <Col>
                        <FadeLoad>
                            <h5 className='fw-bold text-uppercase color-light-steel'>Service Packages</h5>
                            <h1 className='fs-60'>Electric vehicle charging.
                                <br />
                                <strong>Simplified.</strong>
                            </h1>
                        </FadeLoad>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col lg={4} className='my-2'>
                        <StrategicAssessorCard />
                    </Col>
                    <Col lg={4} className='my-2'>
                        <ProjectPlannerCard />
                    </Col>
                    <Col lg={4} className='my-2'>
                        <ProjectExecuterCard />
                    </Col>
                </Row>
                <Row>
                    <Col className='pt-3'>
                        <p className='border bg-silver rounded fw-bold p-2'>
                            * Additional time and deliverables billed at $275/hr for all service packages.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container className='py-5'>
                <Row>
                    <Col>
                        <PackageDetailsTable />
                    </Col>
                </Row>
            </Container>
            <Container fluid className='bg-sun vh-75'>
                <Row className='justify-content-evenly align-items-center pt-5'>
                    <Col lg={5}>
                        <h2>Frequently Asked <strong>Questions</strong></h2>
                        <p>Have questions about Power Boom's services? We have answers</p>
                        <PWRPrimaryButtonDark href='/contact-us'>Contact Us</PWRPrimaryButtonDark>
                        <br/>
                        <br/>
                    </Col>
                    <Col lg={6}>
                        <PackageDetailsFAQ />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
}

export default ServicePackages;