import { Container, Button, Carousel, Row, Col } from "react-bootstrap";
import "../GlobalStyle.css";
import { Form, Input } from "antd";
import { useEffect } from "react";
import { Istep1 } from "./types";
import { useMutation } from "@apollo/client";
import { CONTACT_US } from "./mutation";

import { PWRPrimaryBtn } from "../styled/Buttons";


const { TextArea } = Input;

type Iprops = {
  formState: {
    formStep1: Istep1 | undefined;
    setFormValues1: React.Dispatch<React.SetStateAction<Istep1 | undefined>>;
  };
  next: () => void;
};

const ContactStep1 = (props: Iprops) => {
  const { next } = props;

  const [form] = Form.useForm();

  const [contactUs, { loading, error }] = useMutation(CONTACT_US, {
    onCompleted() {
      next();
    },
    onError(err) {
      console.log(err);
    },
  });

  function onFinish(values: any) {
    contactUs({
      variables: {
        input: {
          email: values?.email,
          message:" ",
          name: `${values?.firstName} ${values?.lastName}`,
          phone: values?.phone || " ",
          comment: values?.comment,
          address: values?.address || " ",
        },
      },
    });
    // next();
  }

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Container>
          <Row>
            <Col>
              <h2 className="fs-24 fw-bold">
                Send a message
              </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col md={6}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter your first name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please enter your last name",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                      {
                        required: true,
                        message: "Please enter an email address",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    label="Phone"
                    name="phone"
                    rules={[
                      {
                        pattern: /^[1-9][0-9]{9}$/,
                        message: "Phone number must be 10 digits ",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item label="Facility Address" name="address">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="comment"
                    label="Comment"
                  >
                    <TextArea
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <PWRPrimaryBtn type="submit">
            Submit Comment
          </PWRPrimaryBtn>
          <br/>
          <br/>
        </Container>
      </Form>
    </>
  );
};

export default ContactStep1;
