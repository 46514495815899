import { Container, Button, Carousel, Row, Col } from 'react-bootstrap';
import '../GlobalStyle.css';
import styled from 'styled-components'
import { Form, Input } from 'antd'

const { TextArea } = Input;

const ContactStep2 = () => {
    return (
        <>
        <br/>
            <br/>
            <Container className='py-2 px-0'>
                <Row>
                    <Col>
                        <h1>Thank you for your message</h1>
                        <p>
                            We'll follow-up with you in 1-2 business days.
                        </p>
                    </Col>
                </Row>
            </Container>
            <br/>
            <br/>
        </>
    );
}

export default ContactStep2;