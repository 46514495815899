import { Container, Row, Col } from "react-bootstrap";
import "../GlobalStyle.css";
import styled from "styled-components";

import LinkedIn from "../../assets/icons/Social - LinkedIn.png";
import Facebook from "../../assets/icons/Social - Facebook.png";
import Insta from "../../assets/icons/Social - Instagram.png";
import Twitter from "../../assets/icons/Social - Twitter.png";
import Youtube from "../../assets/icons/Social - Youtube.png";

import React, { useState } from "react";
import { Button, Input, Form, Affix } from "antd";
import { STAY_UPDATED } from "../Contact/mutation";
import { useMutation } from "@apollo/client";
import { PWRSecondaryButton } from "../styled/Buttons";
import { CloseOutlined } from "@ant-design/icons";
import { useMediaQuery } from 'react-responsive';

const FooterContent = styled.div`
  text-transform: uppercase;
  background-color: #333333;
  padding: 2rem 1rem;
  color: #fff;

  li {
    list-style-type: none;
  }

  ul {
    list-style-type: none;
    padding: 0 !important;
  }

  .footer-link {
    color: #fff;
    text-decoration: none;
  }

  .footer-link:hover {
    border-bottom: 1px solid #ffcf01;
  }
`;
const SocialLinks = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;

  a {
    color: #ffffff;
    // margin: auto .5rem;
  }
`;

const StayInTouch = styled.div`
  background-color: #e9ecef;
  padding-left:10px;
  padding-top:15px;
  padding-right:10px;
  padding-bottom:0px;
`;
export const stayConnectedKey = "stay-connected";

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const Footer = () => {
  const initialForSit = localStorage.getItem(stayConnectedKey)
    ? false
    : true;
  const [email, setEmail] = useState("");
  const [affixVisible, setAffixVisible] = useState(initialForSit);

  const [form] = Form.useForm();

  const [addNewsletterUser, { loading, error }] = useMutation(STAY_UPDATED, {
    onCompleted() {
      localStorage.setItem(stayConnectedKey, "Yes");
      setAffixVisible(false);
    },
    onError(err) {
      console.log(err);
    },
  });

  const handleOk = () => {
    form
      .validateFields()
      .then(() => {
        addNewsletterUser({
          variables: {
            email,
          },
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAffixClose = () => {
    setAffixVisible(false);
  };
  const isMobile = useMediaQuery({ maxWidth: 767 }); 
  return (
    <>
      {affixVisible && (isMobile ? (
        <Affix offsetBottom={0}>
        <StayInTouch>
          <Row>
            <Col xs={11}>
              <center>
                <p>
                  <b>Stay in the know!</b> Get EV charging incentive and
                  product updates
                </p>
              </center>
            </Col>
            <Col className="px-1" xs={1}>
            <Button
              type="text"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={handleAffixClose}
            />
            </Col>
          </Row>
          <Row>
          <Col>
              <center>
              <Form form={form}>
                <Form.Item
                  label="Email"
                  name="email"
                  style={{ width: "60%" }}
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                    {
                      required: true,
                      message: "Please enter an email address",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter your email"
                    value={email}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              </Form>
              </center>
            </Col>
          </Row>
          <Row>
          <Col>
              <center>
              <PWRSecondaryButton type="submit" onClick={handleOk}>
                  JOIN
                </PWRSecondaryButton>
                <br/>
                <br/>
              </center>
          </Col>
          </Row>
        </StayInTouch>
      </Affix>
      ) : (
        <Affix offsetBottom={0}>
          <StayInTouch>
            <Row>
              <Col sm={6}>
                <center>
                  <p>
                    <b>Stay in the know!</b> Get EV charging incentive and
                    product updates
                  </p>
                </center>
              </Col>
              <Col>
                <Form form={form}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                      {
                        required: true,
                        message: "Please enter an email address",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%px" }}
                      placeholder="Enter your email"
                      value={email}
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </Form>
              </Col>

              <Col>
                <PWRSecondaryButton type="submit" onClick={handleOk}>
                  JOIN
                </PWRSecondaryButton>
              </Col>
              <Button
                type="text"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={handleAffixClose}
              />
            </Row>
          </StayInTouch>
        </Affix>
      ))}

      <FooterContent>
        <Container fluid className="text-center text-lg-start">
          <Row>
            <Col lg={3} md={12} className="text-center pb-4 pb-lg-0">
              <a href="/">
                <img src="images/Power Boom - White.png" width={180} />
              </a>
            </Col>
            <Col lg={3} md={3}>
              <ul>
                <li>
                  <a href="/" className="footer-link">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/services" className="footer-link">
                    Services
                  </a>
                </li>
                <li>
                  <a href="/service-packages" className="footer-link">
                    Service Packages
                  </a>
                </li>
                <li>
                  <a href="/about" className="footer-link">
                    About
                  </a>
                </li>
                <li>
                  <a href="/contact-us" className="footer-link">
                    Contact Us
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={3}>
              <ul>
                <li>
                  1-858-799-4583
                  <br />
                  <a href="mailto:info@pwrboom.com" className="footer-link">
                    info@PwrBoom.com
                  </a>
                </li>
              </ul>
            </Col>
            <Col lg={3} md={3}>
              <ul>
                {/* <li>
                                    <a href='#' className='footer-link'>Privacy</a>
                                </li>
                                <li>
                                    <a href='#' className='footer-link'>Terms & Conditions</a>
                                </li> */}
                <li>Copyright © {getCurrentYear()} PowerBoom.</li>
                <li>All Rights Reserved.</li>
              </ul>

              <SocialLinks>
                <div className="pt-2 pb-4">
                  <a
                    href="https://www.linkedin.com/company/power-boom/about/"
                    target="_blank"
                  >
                    <img src={LinkedIn} alt="linkedin" width={24} />
                  </a>
                  {/* <a href="https://www.instagram.com/pwr_boom/" target="_blank">
                    <img
                      src={Facebook}
                      className="mx-4"
                      alt="facebook"
                      width={24}
                    />
                  </a>
                  <a
                    href="https://twitter.com/i/flow/login?redirect_after_login=%2FPWR_Boom"
                    target="_blank"
                  >
                    <img src={Insta} alt="instagram" width={24} />
                  </a>
                  <a
                    href="https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fbestpayment.solutions%2F"
                    className="mx-4"
                    target="_blank"
                  >
                    <img src={Twitter} alt="instagram" width={24} />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCcvVdIQg7ARER_Bbjxpm5Ng"
                    target="_blank"
                  >
                    <img src={Youtube} alt="instagram" width={24} />
                  </a> */}
                </div>
              </SocialLinks>
            </Col>
          </Row>
        </Container>
      </FooterContent>
    </>
  );
};

export default Footer;
