import { Container, Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import { Steps, theme } from "antd";
import styled from "styled-components";
import '../GlobalStyle.css';
import ContactStep1 from "./ContactStep1";
import ContactStep2 from "./ContactStep2";
import ContactStep3 from "./ContactStep3";
import { Istep1Final, Istep2Final } from "./types";

const ContactSteps = styled.div`
  .ant-steps {
    display: none;
  }

  box-shadow: 0px 0px 10px rgba(0,0,0,.4);
  border-radius: .4rem;
  background-color: #ffffff;

`

const SunBG = styled.div`
  background-color: #FFCF01;
`;

const Stepper = () => {

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  // state to maintain form data
  const [formStep1, setFormValues1] = useState<Istep1Final>();
  const [formStep2, setFormValues2] = useState<Istep2Final>();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "First",
      content: <ContactStep1 formState={{ formStep1, setFormValues1 }} next={next} />,
    },
    // {
    //   title: "Second",
    //   content: <ContactStep2 formState={{ formStep1, formStep2, setFormValues2 }} next={next} prev={prev} />,
    // },
    {
      title: "Last",
      content: <ContactStep3 />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <Container fluid className="px-0">
        <Row className="align-items-center">
          <Col className="px-0">
            <SunBG className="p-4 p-lg-5">
              <ContactSteps className="pt-4 p-lg-5">
                <Steps current={current} items={items} />
                <div>
                  {current === 0 ? (
                    <ContactStep1 formState={{ formStep1, setFormValues1 }} next={next} />
                  ) : (
                    <ContactStep3 />
                  )}
                </div>
              </ContactSteps>
            </SunBG>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Stepper;
