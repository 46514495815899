import { Container, Row, Col, Button } from "react-bootstrap";
import React, { useState } from "react";
import { Steps, theme, Form, FormInstance } from "antd";
import { PWRPrimaryBtn, BackArrow, BackButton, PWRSecondaryButton, PWRPrimaryButton } from "../styled/Buttons";
import styled from "styled-components";
import '../GlobalStyle.css';
import PaymentStep1 from "./PaymentStep1";
import PaymentStep2 from "./PaymentStep2";
import PaymentStep3 from "./PaymentStep3";
import OrderConfirmation from "./OrderConfirmation";
import { Istep1Final, Istep2Final } from "./types";
import PaymentSummary from "./PaymentSummary";
import { useMutation } from "@apollo/client";
import { PLACE_ORDER } from "./mutation";
import { cartAmountKey, cartIncludeKey, cartNameKey } from "./ServicePackageCartCards";

const PaymentStepper = () => {

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);
  const [hide, setHide] = useState(false);
  // state to maintain form data

  const [form] = Form.useForm()
  const [formStep1, setFormValues1] = useState<Istep1Final>();
  const [formStep2, setFormValues2] = useState<Istep2Final>();

  const next = () => {
    form.validateFields().then(() => {
			setCurrent(current + 1)
		}).catch((err)=>{
      console.log('err', err)
    })
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const [addCustomerOrder, { loading, error }] = useMutation(PLACE_ORDER, {
    onCompleted() {
      setHide(true)
     setCurrent(current + 2)
     localStorage.removeItem(cartNameKey);
     localStorage.removeItem(cartAmountKey);
     localStorage.removeItem(cartIncludeKey);
    },
    onError(err) {
      console.log(err);
    },
  });

  const onFinish = async (form: FormInstance) => {
  
  }
  const onFormSubmission = (values: any) =>{
    const packageName = localStorage.getItem(cartNameKey) || ""
    const packageAmount = localStorage.getItem(cartAmountKey) || ""
    const includedItems:any = localStorage.getItem(cartIncludeKey) || []
    const packageDetails = JSON.parse(includedItems)
    const rawValues = form.getFieldsValue([
			// Step 1
      'companyName',
			'firstName',
			'lastName',
			'phone',
			'email',

      // Step 2
      'facility',
      'street',
			'city',
			'state',
			'zip',
		])
    console.log('rawValues', rawValues)
    addCustomerOrder({
      variables: {
        input: {
          companyName: rawValues.companyName,
          firstName: rawValues.firstName,
          lastName: rawValues.lastName,
          phone: rawValues?.phone || " ",
          email: rawValues?.email,
          facilityType: rawValues?.facility,
          address1: rawValues?.street,
          address2: "",
          city: rawValues?.city,
          state: rawValues?.state,
          zip: rawValues?.zip,
          packageAmount,
          packageName,
          packageDetails
        },
      },
    });
  }

  const steps = [
    {
      title: "Contact",
      content: <PaymentStep1 onFinish={onFinish} form={form} formState={{ formStep1, setFormValues1 }} next={next} />,
    },
    {
      title: "Facility",
      content: <PaymentStep2 onFinish={onFinish} form={form} formState={{ formStep1, formStep2, setFormValues2 }} next={next} prev={prev} />,
    },
    {
      title: "Summary",
      content: <PaymentSummary onFinish={onFinish} form={form} formState={{ formStep1, formStep2, setFormValues1, setFormValues2 }} next={next} prev={prev} />,
    },
    {
      title: "Confirm",
      content: <OrderConfirmation />,
    },
  ];
  
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  return (
    <>
      <Steps current={current} items={items} />
      <br />
      <div>
        {current === 0 ? (
          <PaymentStep1 onFinish={onFinish} form={form} formState={{ formStep1, setFormValues1 }} next={next} />
        ) : current === 1 ? (
          <PaymentStep2
            onFinish={onFinish} form={form}
            formState={{ formStep1, formStep2, setFormValues2 }}
            next={next}
            prev={prev}
          />
        ) : current === 2 ? (
          <PaymentSummary onFinish={onFinish} form={form} formState={{ formStep1, formStep2, setFormValues1, setFormValues2 }} next={next} prev={prev} />
        ) : (
          <OrderConfirmation />
        )}
        <Col className="text-end">
        {current !==0 ? (
          <PWRSecondaryButton type="submit" onClick={() => prev()} hidden={hide}>
          Back
        </PWRSecondaryButton>
        ): ""}
        &nbsp;&nbsp;&nbsp;
          {
            current === steps.length - 2 ? (
              <PWRPrimaryBtn type="submit" onClick={onFormSubmission} hidden={hide}>
            Confirm Order
          </PWRPrimaryBtn>
            ) : (
              <>
              <PWRPrimaryBtn type="submit" onClick={next} hidden={hide}>
            Continue
          </PWRPrimaryBtn>
        
          </> 
          )
          }
        </Col>
      </div>
    </>
  );
};

export default PaymentStepper;