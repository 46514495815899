
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import '../GlobalStyle.css';
import styled from 'styled-components';
import { BackButton, BackArrow } from '../styled/Buttons';

import PWRLogo from '../../assets/images/Power Boom - Horizontal Color.png'; 


const CartNavigation = () => {
    return (
        <>
            <Navbar className='shadow-sm bg-white m-2' sticky='top'>
                <Container fluid>
                    <Navbar.Brand href="/"><img src={PWRLogo} alt="Power Boom Logo" width='200px' /></Navbar.Brand>
                </Container>
            </Navbar>
            <BackButton className='ms-4' href='/service-packages'><BackArrow />Back to Service Packages</BackButton>
        </>
    );
}


export { CartNavigation }