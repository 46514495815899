import { Container, Row, Col } from "react-bootstrap";
import "../GlobalStyle.css";
import { Form, Input, FormInstance } from "antd";
import { Istep1 } from "./types";


const { TextArea } = Input;

type Iprops = {
  formState: {
    formStep1: Istep1 | undefined;
    setFormValues1: React.Dispatch<React.SetStateAction<Istep1 | undefined>>;
  };
  onFinish: (form: FormInstance) => void
	form?: FormInstance
  next: () => void;
  disabled?: boolean
};

const PaymentStep1 = (props: Iprops) => {
  const { next, form, onFinish, disabled } = props;

  return (
    <>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Container fluid>
          <Row>
            <Col className="border-bottom">
              <h5 className="fw-bold">
                Contact Information
              </h5>
            </Col>
          </Row>
          <Row className="pt-3">
          <Col md={12}>
              <Form.Item
                label="Name of Company/Organization"
                name="companyName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your company/organization name",
                  },
                ]}
              >
                <Input disabled={disabled}/>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your first name",
                  },
                ]}
              >
                <Input disabled={disabled}/>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your last name",
                  },
                ]}
              >
                <Input disabled={disabled}/>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                  {
                    required: true,
                    message: "Please enter an email address",
                  },
                ]}
              >
                <Input disabled={disabled}/>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    pattern: /^[1-9][0-9]{9}$/,
                    message: "Phone number must be 10 digits ",
                  },
                ]}
              >
                <Input disabled={disabled}/>
              </Form.Item>
            </Col>
          </Row>
        </Container>
      </Form>
    </>
  );
};

export default PaymentStep1;
