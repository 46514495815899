import styled from "styled-components"

const HoverCardShadow = styled.a`
    background-color: #ffffff;
    border-radius: .4rem;
    text-decoration: none;
    transition: .5s ease;
    padding: 1.5rem;
    width: 100%;
    height: 100%;
    display: inline-block;
    box-shadow: 0px 0px 10px #cccccc;

    &:hover {
        box-shadow: 0px 0px 30px #cccccc;

    }
`

const PackageCard = styled.div`
    background-color: #ffffff;
    border-radius: .4rem;
    padding: 1.25rem;
    border: 1px solid #cccccc;
    width: 100%;
    height: 100%;

    ul {
        font-size: 1.125rem;
        color: #333333;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        padding: .25rem 0rem;
        
        img {
            margin-right: .5rem;
         }
    }

    .package-title {
        display: flex;
        justify-content: space-between;
        font-size: 1.25rem;
        font-weight: bold;
        color: #333333;    
    }
`

const SunEdgeCard = styled.div`
    background-color: #ffffff;
    border-radius: .4rem;
    height: 100%;
    padding: 1.25rem;
    border-left: 10px solid #FFCF01;
`

const ShadowCardNoHover = styled.div`
    box-shadow: 0px 4px 20px #ccc;
    border-radius: .4rem;
`

const ForestEdgeShadowCardNoHover = styled.div`
    box-shadow: 0px 4px 20px #ccc;
    border-radius: .4rem;
    border-top: 10px solid #009247;
    background-color: #ffffff;
    padding: 1.25rem;

    ul {
        font-size: 1.125rem;
        color: #333333;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        padding: .25rem 0rem;
        
        img {
            margin-right: .5rem;
         }
    }

    .package-title {
        display: flex;
        justify-content: space-between;
        font-size: 1.25rem;
        font-weight: bold;
        color: #333333;    
    }
`


export { HoverCardShadow, SunEdgeCard, PackageCard, ShadowCardNoHover, ForestEdgeShadowCardNoHover }
