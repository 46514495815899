import { Row, Col, Container } from 'react-bootstrap';
import '../GlobalStyle.css';
import styled from 'styled-components'
import { PWRPrimaryButtonDark } from '../styled/Buttons';


const CTA = () => {
    return (
        <>
            <Container fluid className='vh-50 bg-sun'>
                <Row className='p-md-5 align-items-center justify-content-center text-center'>
                    <Col md={6} className='mb-md-0'>
                        <h2 className='pb-4'>Want an EV charging plan for your facility?</h2>
                        <PWRPrimaryButtonDark href='/service-packages'>Get Started</PWRPrimaryButtonDark>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CTA;